import api from 'service/api'

const dponetAPI = api.create('dponet', false)

const destroy = async ({
  bannerId = '',
  bannerUrlId = '',
}: BannerRequestType) =>
  await dponetAPI.delete(`/banners/${bannerId}/urls/${bannerUrlId}`)

type BannerRequestType = {
  bannerId?: number | string
  bannerUrlId?: number | string
}

export default {
  destroy,
}
