import { ChangeEvent, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Link2 as Link2Icon, Loader as LoaderIcon } from 'react-feather'
import { size } from 'lodash'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Grid,
  InputAdornment,
  Tooltip,
  Typography,
} from '@mui/material'

import { DetailsReadingCard, ScannerCard } from './components'
import { AlertDialog, BasicSection, Select } from 'components'

import { formatters } from 'helpers'

import { useSnackbar } from 'hooks'

import { CookieValidationCardType } from './cookieValidationCard.types'
import { BannerConfigurationSchemaType, UrlType } from 'types/banner.types'

import { theme } from 'theme'
import service from 'service'
import constants from 'constants/index'

const CookieValidationCard = ({
  cookies,
  banner,
  refresh,
}: CookieValidationCardType) => {
  const [loading, setLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [scanUrl, setScanUrl] = useState<number | undefined>(
    banner?.urls[0]?.id
  )

  const { snackbar } = useSnackbar()
  const { watch } = useFormContext<BannerConfigurationSchemaType>()

  const urls = watch('urls')

  const loadingScanner =
    loading ||
    banner?.scanner?.status === constants.banner.INITIATED_SCANNER_STATUS_ID

  const selectItems = banner?.urls.map((url) => ({
    id: url.id,
    name: url.url,
  }))

  const handleOpenDialog = () => setOpenDialog(true)
  const handleCloseDialog = () => setOpenDialog(false)

  const handleScanUrlChange = (event: ChangeEvent<HTMLInputElement>) =>
    setScanUrl(Number(event.target.value))

  const arraysAreEqual = (arr1: UrlType[], arr2: UrlType[]) => {
    if (size(arr1) !== size(arr2)) return false

    return arr1.every((item1: UrlType, index: number) => {
      const item2 = arr2[index]
      return item1.id === item2.id && item1.url === item2.url
    })
  }

  const disableScanner = !arraysAreEqual(urls as UrlType[], banner?.urls)

  const handleScan = async () => {
    try {
      setLoading(true)
      await service.dpoeasy.banner.scanner({
        bannerId: banner?.id,
        urlId: scanUrl,
      })
      snackbar.open({
        message:
          'Escaneamento iniciado com sucesso! Isso pode levar alguns segundos.',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (
      banner?.scanner?.status === constants.banner.INITIATED_SCANNER_STATUS_ID
    )
      handleOpenDialog()
  }, [banner?.scanner?.status])

  return (
    <BasicSection
      title="Cookies"
      extraActions={
        <Typography color="primary" fontWeight={500}>
          04/04
        </Typography>
      }
    >
      <Box p={4} bgcolor={theme.palette.custom.light} borderRadius={1}>
        <Typography color="primary" textAlign="justify">
          Verifique o código do seu site clicando no botão "Escanear". Nosso
          sistema analisará automaticamente o código em busca de erros ou
          inconsistências. Aguarde o feedback que indicará qualquer problema ou
          confirmará que tudo está correto.
        </Typography>
      </Box>
      <Box>
        <Typography color="primary" fontWeight={600} gutterBottom>
          Detalhes da Leitura
        </Typography>
        <DetailsReadingCard scanner={banner?.scanner} />
      </Box>
      {banner?.scanner?.status ===
        constants.banner.INITIATED_SCANNER_STATUS_ID && (
        <Alert severity="warning" variant="outlined">
          O seu site está sendo escaneado, este processo pode levar até 2
          minutos. Por favor, aguarde alguns instantes e atualize a página se
          necessário. Obrigado pela compreensão!
        </Alert>
      )}
      <Grid container spacing={4} alignItems="center" justifyContent="end">
        <Grid item xs={12} md={3} xl={2}>
          <Select
            label="URL para escaneamento"
            items={selectItems || []}
            value={scanUrl}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Link2Icon />
                </InputAdornment>
              ),
            }}
            fullWidth
            onChange={handleScanUrlChange}
          />
        </Grid>
        <Grid item xs={12} md={3} xl={2} zIndex={1}>
          <Tooltip
            title={
              (disableScanner &&
                'Salve as alterações de "Configuração do Banner" antes de prosseguir com o escaneamento.') ||
              (loadingScanner &&
                'Atualize a página para verificar se o escaneamento foi concluído.')
            }
          >
            <Box>
              <LoadingButton
                onClick={handleScan}
                variant="contained"
                startIcon={
                  <LoaderIcon
                    size={18}
                    className={loadingScanner ? 'spin' : ''}
                  />
                }
                disabled={disableScanner || loadingScanner || !scanUrl}
                fullWidth
              >
                {loadingScanner ? 'Escaneando' : 'Escanear'}
              </LoadingButton>
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
      <Box>
        <ScannerCard cookies={cookies} />
      </Box>
      <AlertDialog
        open={openDialog}
        onClose={handleCloseDialog}
        content="O seu site está sendo escaneado, este processo pode levar até 2
          minutos. Por favor, aguarde alguns instantes e atualize a página se
          necessário. Obrigado pela compreensão!"
        severity="warning"
        variant="outlined"
      />
    </BasicSection>
  )
}

export default CookieValidationCard
