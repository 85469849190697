import * as yup from 'yup'

const schema = yup.object({
  name: yup.string().min(4).max(150).required(),
  urls: yup
    .array()
    .of(
      yup.object({
        url: yup.string().min(4).max(256),
      })
    )
    .required(),
  status: yup.string().required(),
})

export type NewBannerModalSchemaType = yup.InferType<typeof schema>

export type NewBannerModalControlType = keyof NewBannerModalSchemaType

export default schema
