import { Fragment } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { X as XIcon } from 'react-feather'
import { size } from 'lodash'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'

import { BasicDialog } from 'components'

import { formatters } from 'helpers'

import { useSnackbar } from 'hooks'

import { NewBannerModalType } from './newBannerModal.types'

import schema, { NewBannerModalSchemaType } from './schema'
import constants from 'constants/index'
import { theme } from 'theme'
import service from 'service'

const NewBannerModal = ({
  open,
  onClose,
  setLoading,
  refresh,
}: NewBannerModalType) => {
  const { snackbar } = useSnackbar()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<NewBannerModalSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      urls: [{ url: '' }],
      status: constants.banner.INCOMPLETE_STATUS_ID,
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'urls',
  })

  const hasOneField = size(fields) < 2

  const handleClose = () => onClose()

  const handleAppendField = () => append({ url: '' })

  const onSubmit = async (data: NewBannerModalSchemaType) => {
    try {
      setLoading(true)

      await service.dpoeasy.banner.create({ ...data })
      snackbar.open({
        message: 'Banner adicionado com sucesso!',
        variant: 'success',
      })

      reset({})
      refresh()
      handleClose()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BasicDialog
      open={open}
      onClose={handleClose}
      title="Novo banner de cookies"
      maxWidth="md"
      dialogActions={
        <Fragment>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form="new-banner-modal-form"
          >
            Adicionar
          </Button>
        </Fragment>
      }
    >
      <Box
        component="form"
        id="new-banner-modal-form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        gap={4}
      >
        <Box p={4} bgcolor={theme.palette.custom.light} borderRadius={1}>
          <Typography color="primary" fontWeight={600} gutterBottom>
            Informação
          </Typography>
          <Typography color="primary" textAlign="justify">
            Um banner de cookies é um aviso exibido nos sites para informar e
            obter o consentimento dos usuários antes de rastrear e armazenar
            seus dados de navegação.
          </Typography>
        </Box>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors?.name}
              helperText={errors?.name?.message}
              type="text"
              label="Nome"
              fullWidth
            />
          )}
          control={control}
          name="name"
        />
        {fields?.map((item, index) => (
          <Controller
            key={item?.id}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors?.urls?.[index]?.url}
                helperText={errors?.urls?.[index]?.url?.message}
                type="text"
                label={`${hasOneField ? '' : `${index + 1}º `}URL`}
                fullWidth
                {...(!hasOneField
                  ? {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <XIcon onClick={() => remove(index)} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      },
                    }
                  : {})}
              />
            )}
            control={control}
            name={`urls.${index}.url`}
          />
        ))}
        <Box p={4} bgcolor={theme.palette.custom.light} borderRadius={1}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={8}>
              <Typography color="primary" fontWeight={600} gutterBottom>
                Possui mais de uma URL para o mesmo site?
              </Typography>
              <Typography color="primary" textAlign="justify">
                Adicione as URLs do seu site para que o banner seja exibido em
                todas elas. Não esqueça de variações como "www" e sem "www".
                Exemplo: "https://www.site.com" e "https://site.com".
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} alignContent="center">
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleAppendField}
              >
                Adicionar URL
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </BasicDialog>
  )
}

export default NewBannerModal
