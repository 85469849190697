import React, { useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { flattenDeep, isEmpty, map, compact, isNil } from 'lodash'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Grid } from '@mui/material'

import {
  BannerCustomizationCard,
  BannerConfigurationCard,
  BannerInstallationCard,
  CookieValidationCard,
} from './components'

import { formatters } from 'helpers'

import { useSnackbar } from 'hooks'

import {
  BannerConfigurationSchemaType,
  DescriptionType,
} from 'types/banner.types'
import { BannerFormType } from './bannerForm.types'

import { theme } from 'theme'
import schema from './schema'
import service from 'service'
import constants from 'constants/index'
import { mergeDescriptions } from 'helpers'

const BannerForm = ({
  banner,
  loading,
  refresh,
  setLoading,
}: BannerFormType) => {
  const [descriptions, setDescriptions] = useState<DescriptionType[]>(
    mergeDescriptions(banner?.descriptions || [])
  )
  const { snackbar } = useSnackbar()
  const cookies = flattenDeep(
    map(banner?.classification, ({ cookies, name, id }) =>
      map(cookies, (cookie) => ({ ...cookie, category: { id, name } }))
    )
  )

  const handleDescriptionChange = useCallback(
    (languageCode: string, value: string) => {
      setDescriptions((prevDescriptions) =>
        prevDescriptions.map((desc) =>
          desc.language.code === languageCode
            ? { ...desc, description: value }
            : desc
        )
      )
    },
    []
  )

  const readaptedUrls = banner?.urls?.map((url) => ({
    ...url,
    originalId: url.id,
  }))

  const { control, handleSubmit, ...formRest } =
    useForm<BannerConfigurationSchemaType>({
      resolver: yupResolver(schema),
      defaultValues: {
        name: banner?.name || '',
        urls: readaptedUrls || [{ url: '' }],
        cookies: map(
          cookies,
          ({ id, platform, category: { id: categoryId } }) => ({
            id,
            platform: platform ?? undefined,
            categoryId: categoryId ?? undefined,
          })
        ),
        primaryColor: banner?.primaryColor || theme.palette.primary.main,
        secondaryColor: banner?.secondaryColor || theme.palette.secondary.main,
        logo: undefined,
        position: banner?.position || constants.banner.BANNER_LEFT_POSITION,
        hideOnAccept: banner?.hideOnAccept || false,
      },
    })

  const isMisconfigured =
    banner?.status === constants.banner.INCOMPLETE_STATUS_ID ||
    isNil(banner?.status)

  const onSubmit = useCallback(
    async (data: BannerConfigurationSchemaType, isPublish = false) => {
      try {
        setLoading(true)

        const { cookies: cookieDirty } = formRest.formState.dirtyFields
        const bannerData = { ...data, descriptions }

        if (!isEmpty(cookieDirty) && !!data?.cookies) {
          const cookiesData = compact(
            cookieDirty?.map((_, index) => data?.cookies?.[index]) ?? []
          )

          await service.dpoeasy.cookie.update({
            bannerId: banner?.id,
            cookies: cookiesData,
          })
        }

        delete bannerData?.cookies

        if (bannerData?.logo) {
          await service.dpoeasy.banner.uploadLogo({
            bannerId: banner?.id,
            logo: bannerData.logo as File,
          })

          delete bannerData?.logo
        }

        await service.dpoeasy.banner.update({
          bannerId: String(banner?.id),
          banner: {
            ...bannerData,
            ...(isMisconfigured && isPublish && { status: 'active' }),
          },
        })

        snackbar.open({
          message: `Banner de cookies atualizado${isMisconfigured && isPublish ? ' e publicado' : ''} com sucesso`,
          variant: 'success',
        })
        formRest.reset({})
        refresh()
      } catch (error) {
        console.error(error)
        snackbar.open({
          message: formatters.errorMessage(error, false),
          variant: 'error',
        })
      } finally {
        setLoading(false)
      }
    },

    //eslint-disable-next-line
    [formRest.formState.dirtyFields, descriptions]
  )

  return (
    <FormProvider control={control} handleSubmit={handleSubmit} {...formRest}>
      <Grid
        container
        spacing={8}
        component="form"
        id="cookie-configuration"
        onSubmit={handleSubmit((data) => onSubmit(data))}
      >
        <Grid item xs={12}>
          <BannerConfigurationCard setLoading={setLoading} banner={banner} />
        </Grid>
        <Grid item xs={12}>
          <BannerCustomizationCard
            banner={banner}
            descriptions={descriptions}
            onDescriptionChange={handleDescriptionChange}
          />
        </Grid>
        <Grid item xs={12}>
          <BannerInstallationCard bannerToken={banner?.token} />
        </Grid>
        <Grid item xs={12}>
          <CookieValidationCard
            banner={banner}
            cookies={cookies}
            refresh={refresh}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="end">
            <Grid item xs={12} md={3} xl={2}>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={loading}
                fullWidth
              >
                Salvar
              </LoadingButton>
            </Grid>
            {isMisconfigured && (
              <Grid item xs={12} md={3} xl={2}>
                <LoadingButton
                  variant="contained"
                  type="button"
                  fullWidth
                  loading={loading}
                  onClick={handleSubmit((data) => onSubmit(data, true))}
                >
                  Publicar
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default React.memo(BannerForm)
