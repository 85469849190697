import axios from 'axios'

import api from 'service/api'
import service from 'service'
import { getBaseURL } from 'service/env'

const dpoeasyAPI = api.create('dpoeasy', false)
const dpoeasyURL = getBaseURL('dpoeasy')

const get = async ({ bannerId = '', ...params }) =>
  await dpoeasyAPI.get(`/banners/${bannerId}`, { params })

const create = async ({ ...data }) =>
  await dpoeasyAPI.post('/banners', {
    banner: data,
  })

const update = async ({ bannerId = '', ...data }) =>
  await dpoeasyAPI.put(`/banners/${bannerId}`, { ...data })

const updateByToken = async ({ token = '', ...data }) =>
  await dpoeasyAPI.patch('/banners/update_by_token', { token, ...data })

const uploadLogo = async ({
  bannerId,
  logo,
}: {
  bannerId: number
  logo: File
}) => {
  const data = new FormData()

  data.append('logo', logo)

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dpoeasy.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${dpoeasyURL}/banners/${bannerId}/upload_logo`,
    data: data,
    ...config,
  })
}

const scanner = async ({
  bannerId,
  urlId = '',
}: {
  bannerId: number
  urlId?: number | string
}) =>
  await dpoeasyAPI.get(`/banners/${bannerId}/scanner`, {
    params: { urlId },
  })

const scannerByToken = async (token: string) =>
  await dpoeasyAPI.get('/banners/scanner_by_token', { params: { token } })

const checkUrl = async (params: object) =>
  await dpoeasyAPI.get('/banners/check_url', { params })

const checkUrlUsing = async (params: object) =>
  await dpoeasyAPI.get('/banners/check_url_already_used', { params })

const checkBannerStatus = async () =>
  await dpoeasyAPI.get('/banners/check_scanner_status')

export default {
  get,
  create,
  update,
  updateByToken,
  uploadLogo,
  checkUrl,
  checkUrlUsing,
  scanner,
  scannerByToken,
  checkBannerStatus,
}
